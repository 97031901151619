<template>
  <div class="md:grid grid-cols-3 mx-auto gap-4">
    <!-- Order -->
    <div class="col-span-2 flex flex-col bg-white rounded shadow" style="min-height: 20rem">
      <div class="">
        <Loading v-if="orderPending" />
        <OrderLineItems
          :createOrder="true"
          v-else-if="currentView === 0"
          :items="order.cart"
          @removeFromCart="removeFromCart"
          :title="currentTitle"
        />
        <Shipping v-else-if="currentView == 1" class="p-4" />
        <Billing v-else-if="currentView == 2" class="p-4" @paymentProcessed="placeOrder" />
        <Finished v-else-if="currentView == 3" :order="order" @clear="clearOrder" class="p-4" />
      </div>
      <div class="mb-2 pt-2 mt-auto" v-if="currentView != 3">
        <OrderTotals
          :products="order.cart"
          :shipping="order.shipping"
          :discount="order.discount"
          :taxRate="taxRate"
        />
      </div>

      <div class="flex justify-end px-4" v-if="selectedUser && order.cart.length > 0">
        <!-- <button @click="printOrder" class="mx-4">Print</button> -->
        <button
          v-if="currentView != 0 && currentView != 3"
          @click="currentView -= 1"
          class="bg-white border rounded-l-md w-40 my-2 hover:bg-gray-400 inline-flex items-center justify-center"
        >
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd"
            />
          </svg>
          Back
        </button>
        <button
          v-if="currentView < 2"
          @click="currentView += 1"
          class="btn-primary w-40 my-2 inline-flex items-center justify-center"
          :class="currentView == 0 ? 'rounded-md' : 'rounded-r-md'"
        >
          Next
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button
          v-if="currentView == 2"
          @click="placeOrder"
          class="btn-primary rounded-r-md w-40 my-2"
        >
          Place Order
        </button>
        <button
          v-if="currentView == 3"
          @click="clearOrder"
          class="btn-primary rounded-r-md w-40 my-2"
        >
          New Order
        </button>
      </div>
    </div>
    <!-- End of order -->

    <div>
      <!-- User -->
      <div>
        <label class="block text-sm font-medium text-gray-700 relative" id="userInput">
          User
          <!-- Legend -->
          <div class="absolute right-1 top-0">
            <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip">
              <font-awesome-icon class="text-lg" icon="info-circle" />
              <span class="tooltiptext shadow text-black p-1 flex flex-col w-64 text-left right-0">
                Only customers marked as a primary account are included in the list.
              </span>
            </div>
          </div>
          <!-- End Legend -->
        </label>
        <CustomerSelect
          :loading="loading"
          :users="users"
          @setUser="setUser"
          @getCustomerSelectUsers="getUsers"
        />
      </div>

      <!-- End User -->

      <!-- Order History -->
      <div :key="quantityKey" class="overflow-y-scroll" style="max-height: 20rem">
        <ListofUserOrders
          :page="false"
          :admin="true"
          :user="selectedUser"
          v-if="user"
          :commercial="true"
          :loading="companyLoading"
        />
      </div>
      <!-- End Order history -->
    </div>

    <!--  Products  -->
    <div class="col-span-3 bg-white shadow rounded p-2 pb-4 my-4 overflow-y-auto" :key="loading">
      <Products @addToCart="addToCart" :associatedAccountNumber="accountNumber" />
    </div>
    <!-- End Products -->
  </div>
</template>

<script>
import ListofUserOrders from '@/components/user/ListofUserOrders';
import Products from './../Products';
import OrderLineItems from './CreateOrderLineItems';
import OrderTotals from '@/components/shared/NewOrderTotals';
import CustomerSelect from '@/components/admin/shared/CustomerSelect';
import CartItem from '@/assets/utils/CartItem';
import Shipping from './CreateOrderShipping';
import Billing from './CreateOrderBilling';
import Finished from './Finished';
import Loading from '@/components/shared/Loading.vue';
export default {
  data: function () {
    return {
      selectedUser: null,
      currentView: 0,
      orderPending: false,
    };
  },
  props: {
    duplicateOrder: { required: false, type: Object, default: null },
    duplicateUser: { required: false, type: Object, default: null },
  },
  computed: {
    quantityKey() {
      return this.$store.getters['products/getQtyKey'];
    },
    users() {
      return this.$store.getters['users/getPrimaryUsers'];
    },
    orders() {
      return this.$store.getters['company/getOrders'];
    },
    order() {
      return this.$store.getters['createOrder/getOrder'];
    },
    loading() {
      return this.$store.getters['createOrder/getLoading'];
    },
    user() {
      return this.$store.getters['createOrder/getUser'];
    },
    stripeToken() {
      return this.$store.state.order.stripeToken;
    },
    companyLoading() {
      return this.$store.getters['company/getLoading'];
    },
    accountNumber() {
      if (this.selectedUser) {
        return this.selectedUser.company ? this.selectedUser.company.accountNumber : 'no-company';
      }
      return 'no-company';
    },
    taxRate() {
      if (this.user && this.user.company && this.user.company.taxExempt) {
        return 0;
      } else {
        return this.$store.getters['order/getTaxRate'];
      }
    },
    userSearch: {
      get() {
        return this.$store.getters['users/getUserSearch'];
      },
      set(val) {
        this.$store.commit('users/setUserSearch', val);
      },
    },
    currentTitle() {
      return this.selectedUser
        ? `Order for: ${this.selectedUser.name} `
        : 'No user is currently selected';
    },
  },
  methods: {
    setUser(user) {
      this.selectedUser = user;
      console.log(user);
      this.$store.dispatch('createOrder/getUser', user._id);
      this.$store.commit('createOrder/setSearch', null);
      this.$store.dispatch('createOrder/getUserProducts', user.company.accountNumber);
      this.$store.commit('order/alterKey');
      this.userSearch = null;
    },

    addToCart(variant) {
      this.$store.commit('order/alterKey');
      let title = `${variant.title} `;
      if (variant.options[0]) {
        title += ` (${variant.options[0]})`;
      }

      if (variant.options[1]) {
        title += ` (${variant.options[1]})`;
      }

      if (variant.options[2]) {
        title += ` (${variant.options[2]})`;
      }
      console.log(variant);

      const cartItem = new CartItem(
        title,
        variant.image,
        variant.product_id,
        variant._id,
        1,
        0,
        variant.price,
        variant.price,
        variant.productNum,
        variant.binLocation,
        variant.quickbooksId,
        variant.vendor,
        variant.lb,
        variant.oz,
        variant.stock,
        variant.isBundle,
        variant.bundleItems,
        variant.bundleDiscountPer
      );
      this.$store.commit('createOrder/addToCart', cartItem);
    },
    removeFromCart(variant) {
      this.$store.commit('order/alterKey');

      this.$store.commit('createOrder/removeFromCart', variant.productNum);
    },
    async placeOrder() {
      this.orderPending = true;
      this.order.status = 'Pending';
      if (this.user == null) {
        this.$store.commit('setError', {
          message: 'No user has been selected',
          color: 'red',
        });
        return;
      }

      //if the token isn't null or the user is marked as delayed payment.
      if (this.order.paid || this.stripeToken || this.order.paymentType !== 'Credit Card') {
        await this.$store.dispatch('createOrder/submitOrder', {
          order: this.order,
          stripeToken: this.stripeToken,
        });
        this.currentView = 3;
      } else if (!this.stripeToken) {
        this.$store.commit('setError', {
          message: 'Please enter a valid credit card in the payment section',
          color: 'red',
        });
      }
      this.orderPending = false;
    },
    clearOrder() {
      this.$store.commit('createOrder/clearOrder');
      this.selectedUser = null;
      this.currentView = 0;
    },
    saveBid() {
      if (!this.order.email) {
        const email = prompt('Email:');
        this.order.email = email;
      } else {
        this.order.customer_id = this.user._id;
        this.order.orderName = `Bid: ${new Date().toString().substring(0, 10)}`;
      }
      this.order.status = 'Bid';
      this.$store.dispatch('createOrder/saveCart', this.order);
      this.clearOrder();
    },
    setUpDuplicateOrder() {
      const response = this.$store.getters['createOrder/getDuplicate'];
      if (!response || !response.order) {
        return;
      }

      if (this.users) {
        let user = null;
        this.users.some((el) => {
          if (el.email === response.user_email) {
            user = el;
            return;
          }
        });
        if (user) {
          this.setUser(user);
        }
      }
      this.$store.commit('createOrder/setOrder', { cart: response.order });
    },
    getUsers(value) {
      this.$store.dispatch('users/getPrimaryUsers', `&search=${value}`);
    },
  },
  mounted() {
    this.selectedUser = this.user;
    this.clearOrder();
    this.setUpDuplicateOrder();
    this.$store.commit('users/setUserSearch', null);
    this.$store.dispatch('users/getPrimaryUsers', '');
  },
  components: {
    ListofUserOrders,
    Products,
    OrderLineItems,
    OrderTotals,
    Shipping,
    Billing,
    Finished,
    Loading,
    CustomerSelect,
  },
};
</script>
<style scoped></style>
